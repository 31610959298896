$( document ).ready(function() {
    if (!$('.certificates').length) {
        return;
    }

    $('.certificates form.certificate-connect').on('submit', function() {
        var form = $(this);

        form.save(function(data) {
            if (data) {
                var infobox = form.closest('.infobox'),
                    configbox = infobox.prev();

                if ('html' in data) {
                    infobox.html(data.html); 
                }
                if (data.status) {
                    if (data.status == 'W') {
                        configbox.find('.label-success').addClass('hidden');
                        configbox.find('.label-waiting').removeClass('hidden');
                    }
                }
            }
        });
        return false;
    });

    // refresh cert list
    $('.certificates').on('refresh', '.certificates-list[data-url-on-demand]', function() {
        var $this = $(this);

        $.ajax({
            url: $this.attr('data-url-on-demand'),
            type: 'get',
            success: function(data) {
                $this.addClass('loaded').html(data);
                $this.closest('.collapse').addClass('loaded');
            } 
        })
    });
    // on collapse open load certificates
    $('.certificates').on('show.bs.collapse', '.collapse:not(.loaded)', function() {
        $(this).find('.certificates-list[data-url-on-demand]').trigger('refresh');
    });

    // on manage modal close, refresh certificate list
    $('body').on('hide.bs.modal', '.certificates-manage-modal', function() {
        var id = $(this).find('.modal-header').attr('data-id');
     
        if (id) { 
            $('.certificates .collapse[data-id='+id+'] .certificates-list[data-url-on-demand]').trigger('refresh');
        }
    });

    // on options modal close, refresh options
    $('body').on('hide.bs.modal', '.certificates-options-modal', function() {
        var id = $(this).find('.modal-header').attr('data-id');
    
        if (id) { 
            $('.certificates [data-id='+id+'] .media-heading .options[data-url]').trigger('refresh');
        }
    });

    // load options
    $('.certificates').on('refresh', '.media-heading .options[data-url]', function() {
        var $this = $(this);

        $.ajax({
            url: $this.attr('data-url'),
            type: 'get',
            success: function(data) {
                $this.replaceWith(data);
            } 
        });
    });
});
