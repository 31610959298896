import Handlebars from './../handlebars-v1.3.0.min'

$(document).ready(function () {
    $('body').on('click', 'a.find-matching-recipes', function(e) {
        e.preventDefault();
        e.stopPropagation();

        var a = $(this), url = a.attr('href'),
            li = a.closest('li');

        $.ajax({
            type: "POST",
            url: url,
            data: {
                name: a.attr('data-recipe-name')
            },
            beforeSend: function () {
                a.addClass('loading');
                $('html').addClass('async-loading');
                li.find('+ .found-recipe, .nothing-found').remove();
            },
            success: function (data) {
                if ('recipes' in data) {
                    $.each(data.recipes, function(k, obj) {
                        var tmpl = $('#found-recipe-template');
                        li.after($(Handlebars.compile(tmpl.html())(obj)));
                    });
                } else {
                    var nf = $('<li><a href="#" data-value="-1" style="font-style: italic; color: #999" disabled></a></li>');
                    nf.find('a').text(li.closest('ul').attr('data-no-recipes-found'));
                    li.after(nf);
                }
            },
            complete: function() {
                a.removeClass('loading')
            }
        });
    });

    //    ------------------------------------- Service Settings ----------------------------------------------------

    // $('#sync-btn').on('click', function () {
    //
    //     // var formdata = $('form.sync-form').serialize();
    //     var url = $(this).attr('action');
    //     // var successUrl = $('form.sync-form').attr('successUrl');
    //     $('.sync-loading-indicator').removeAttr('hidden');
    //     $('.sync-btn-inner').attr('hidden', true);
    //     $.ajax({
    //         type: "POST",
    //         url: url,
    //         data: [],
    //         success: function () {
    //             $('.sync-btn-inner').attr('disabled', true);
    //             $('.sync-loading-indicator').attr('hidden', true);
    //         },
    //
    //     });
    // });

    $('#sync_invoices').on('click', function () {
        var accountId = $('#sync_invoices').attr('data-id');
        var url = '/pos/account/';
        url = url.concat(accountId)
        $.ajax({
            type: "POST",
            url: url,
            data: {sync_invoices: $(this).prop('checked')},
            beforeSend: function () {
                $('html').addClass('async-loading');
            },
            success: function (data) {
                console.log(data)
                $(this).prop('checked', data.syncDelete)
            },
        });
    });

    $('#delete_recipes').on('click', function () {
        var accountId = $('#delete_recipes').attr('data-id');
        var url = '/pos/account/';
        url = url.concat(accountId)
        $.ajax({
            type: "POST",
            url: url,
            data: {delete_recipes: $(this).prop('checked')},
            beforeSend: function () {
                $('html').addClass('async-loading');
            },
            success: function (data) {
                console.log(data.syncDelete)
                $(this).prop('checked', data.syncDelete)
            },
        });
    });


    $('#update_recipes').on('click', function () {
        var accountId = $('#delete_recipes').attr('data-id');
        var url = '/pos/account/';
        url = url.concat(accountId)
        $.ajax({
            type: "POST",
            url: url,
            data: {update_recipes: $(this).prop('checked')},
            beforeSend: function () {
                $('html').addClass('async-loading');
            },
            success: function (data) {
                console.log(data.syncUpdate)
                $(this).prop('checked', data.syncUpdate)
            },


        });
    });

    $('#create_recipes').on('click', function () {
        var accountId = $('#delete_recipes').attr('data-id');
        var url = '/pos/account/';
        url = url.concat(accountId)
        $.ajax({
            type: "POST",
            url: url,
            data: {create_recipes: $(this).prop('checked')},
            beforeSend: function () {
                $('html').addClass('async-loading');
            },
            success: function (data) {
                console.log(data.syncCreate)
                $(this).prop('checked', data.syncCreate)
            },

        });
    });
	
	$('#sync_allergens_to_description').on('click', function () {
        var accountId = $('#sync_allergens_to_description').attr('data-id');
        var url = '/pos/account/';
        url = url.concat(accountId)
        $.ajax({
            type: "POST",
            url: url,
            data: {sync_allergens_to_description: $(this).prop('checked')},
            beforeSend: function () {
                $('html').addClass('async-loading');
            },
            success: function (data) {
                $(this).prop('checked', data.syncAllergens)
            },

        });
    });
	
	$('#sync_additives_to_description').on('click', function () {
        var accountId = $('#sync_additives_to_description').attr('data-id');
        var url = '/pos/account/';
        url = url.concat(accountId)
        $.ajax({
            type: "POST",
            url: url,
            data: {sync_additives_to_description: $(this).prop('checked')},
            beforeSend: function () {
                $('html').addClass('async-loading');
            },
            success: function (data) {
                $(this).prop('checked', data.syncAdditives)
            },

        });
    });


//    ------------------------------------- Recipe ----------------------------------------------------
//
//     $('#sync-recipe-pos-settings-ready2order').ready(function () {
//
//
//         var recipeId = $('#sync-recipe-pos-settings-ready2order').attr('recipeId');
//
//         if(recipeId){
//             var urlWithoutParam = '/pos/ready2order/sync/product/recipe/'
//             var url = urlWithoutParam.concat(recipeId)
//             $.ajax({
//                 type: "GET",
//                 url: url,
//                 success: function (data) {
//                     if(data.status !=='error'){
//
//                         $('.well.syncnnshare').prop('hidden',false);
//                         $('#sync-recipe-pos-settings-ready2order').prop('hidden',false);
//                         $('#sync-recipe-r2o-product').prop('checked', data[0].isSyncing );
//                         console.log("ajax")
//                         console.log(data)
//                     }
//                 },
//                 error:function(error){
//                 }
//
//             });
//         }
//     });

    $('.account-recipe-sync-toggle').ready(function () {
        $('.account-recipe-sync-toggle').each(function () {

            var recipeId = $(this).attr('recipeId');
            var accountId = $(this).attr('accountId');
            var that = this;

            if (recipeId) {
                var url = '/pos/'+accountId+'/sync/product/recipe/'+recipeId;
                $.ajax({
                    type: "GET",
                    url: url,
                    success: function (data) {
                        if (data.status !== 'error') {
                            $(that).find('input[type=checkbox]').first().prop('checked', data.isSyncing);
							$('#dummy_pos_sync_'+accountId).prop('checked', data.isSyncing);
                        }
                    },
                    error: function (error) {
                    }

                });
            } else { // unchecked is default
				$(that).find('input[type=checkbox]').first().prop('checked', false);
				$('#dummy_pos_sync_'+accountId).prop('checked', false);
			}


        });
    });


    $('.account-recipe-sync-toggle').on('click', function () {
        var recipeId = $(this).attr('recipeId')
        var accountId = $(this).attr('accountId');
        var url = '/pos/'+accountId+'/sync/product/recipe/'+recipeId;

        var that = this
        // var url = urlWithoutParam.concat(recipeId)
		/*if(recipeId) {
			$.ajax({
				type: "POST",
				url: url,
				data: {isSyncing: !$(that).find('input[type=checkbox]').first().prop('checked')},
				success: function (data) {
					var val = data.isSyncing
					if (val === 'false') {
						val = false;
					}
					$(that).find('input[type=checkbox]').first().prop('checked', val);
					$('#dummy_pos_sync_'+accountId).prop('checked', val);
				},

			});
		} else {*/
			var shouldSync = !$(that).find('input[type=checkbox]').first().prop('checked');
			$(that).find('input[type=checkbox]').first().prop('checked', shouldSync);
			$('#dummy_pos_sync_'+accountId).prop('checked', shouldSync);
		//}
    });

	init_scroll_load();


    $('#pos-articles-main-content').on('loaded.part loaded.full', function() {
        $(this).find('[data-url-autocomplete][data-autocomplete-autoload=true]').initAutcompleteLoad();
    });
    $('body').on('autocomplete-item-selected', '#pos-articles-main-content [data-url-autocomplete][data-autocomplete-autoload=true]', function(e, data) {
        var $this = $(this), obj = {
            id: data.id,
            name: data.name,
            url: data.url
        };

        var recipe_id = $this.closest('.main-content').find('input.recipe-id');
        $('#pos-articles-main-content input.recipe-id[value="'+data.id+'"]').each(function() {
            var x = $(this);
            if (x.attr('id') != recipe_id.attr('id')) {
                alert('Warnung: Artikel '+x.closest('.main-content').find('.multiple-accounts-post-article-label').text()+' ist schon mit diesem Rezept verbunden.');
                return false;
            }
        });


        var tmpl = $('#found-recipe-template');
        var new_li = $(Handlebars.compile(tmpl.html())(obj));

        $(this).before(new_li);
        new_li.find('a').trigger('clicked');
        new_li.closest('[data-url-autocomplete]').find('[type=text]').typeahead('val', ''); 
    });
    $('body').on('focus', '#pos-articles-main-content .tt-input', function() {
        $(this).select()
    });

    $('#form-pos-products').on('submit', function() {
        var $this = $(this);
        $('#loading').show();
        $this.find('[name=load_and_control]').remove();

        var scroll_load = $this.find('#pos-articles-main-content[data-check-scroll-load=true]');
        if (scroll_load.length && !scroll_load.hasClass('scroll-loaded')) {
            $this.append('<input type="hidden" name="load_and_control" value="1">');
        }

        //var btn = $('#save-multiple-pos-account-form').button('loading');
        $this.save(null,function() {
            //btn.button('reset');
        });
        return false;
    });
    $('#save-multiple-pos-account-form').on('click', function() {
        $('#form-pos-products').trigger('submit');
        return false;
    });
    $('#multiple-accounts-table-title-label-checkboxes').click(function() {
        var $this = $(this), state = (typeof $this.data('checked') !== 'undefined' && $this.data('checked') == true) ? true : false;
        $('#pos-articles-main-content').find('.multiple-accounts-table-title-label-checkboxes [type=checkbox]').prop('checked', state).trigger('updated');
        $this.data('checked', !state);
    });
    $('.unused-recipe-add').click(function() {
        var $this = $(this), obj = {
            name: $this.attr('data-title'),
        };
        var tmpl = $('#unused-recipe-template');
        var line = $(Handlebars.compile(tmpl.html())(obj));
        line.find(':checkbox').attr('name', 'pos_recipe_checkbox_'+$this.attr('data-recipe-id'));

        $('#pos-articles-main-content').append(line);

        var unused_recipe_box =  $this.closest('.unused-recipe-box').hide();
        line.data('right-menu-parent', unused_recipe_box);

        window.scrollTo(0,document.body.scrollHeight);
    });
    $('body').on('click','.unused-recipe-remove', function() {
        var line = $(this).closest('.main-content');
        var right_menu_parent = line.data('right-menu-parent');

        if (right_menu_parent) {
            right_menu_parent.show();
        }

        line.remove();
        return false;
    });
    $('#dont-create-recipe').on('change', function() {
        var on = $(this).is(':checked')

        if (on) {
            $('.pos-product-recipe-dropdown').each(function() {
                var dropdown = $(this),
                    val = dropdown.siblings('input[type=hidden]').val();

                dropdown.data('before-dont-create-recipe', val);
                dropdown.data('before-dont-create-recipe-switch', dropdown.closest('.row').find(':checkbox').prop('checked'));
                dropdown.find('li a[data-value=-1].p-dont-create-recipe').first().trigger('clicked');
            });
        } else {
            $('.pos-product-recipe-dropdown').each(function() {
                var dropdown = $(this),
                    val = dropdown.data('before-dont-create-recipe');

                dropdown.find('li a[data-value="'+val+'"]').trigger('clicked');
                dropdown.closest('.row').find(':checkbox').prop('checked', dropdown.data('before-dont-create-recipe-switch'));
            });
        }
    });
    $('body').on('change updated', '.data-setup-not-complete .multiple-accounts-table-title-label-checkboxes :checkbox', function(e) {
        var $this = $(this)
            dropdown = $this.closest('.row').find('.pos-product-recipe-dropdown');
       
        if (!$this.is(':checked')) {
            dropdown.find('li a[data-value=-1].p-dont-create-recipe').trigger('clicked');
        } else
        if (dropdown.siblings('input[type=hidden]').val() == -1) {
            dropdown.find('li a.recipe-a').first().trigger('clicked');
        }
    });

    $('body').on('change updated', '.dropdown input.recipe-id', function() {
        var $this = $(this), val = $this.val();

        if (val == -1) {
            $this.closest('.row').find(':checkbox').prop('checked', false);
        }
    })
});

