// global functions
import {htmlLoading} from "./global";

'./global'
$( document ).ready(function() {


    $('input[name="nuts[calories]"]')
        .on('keyup keydown keypress', function (e) {
            var kcal = toNumber($(this).val().trim());
            $('input[name="nuts[kj]"]').val(localizedNumber((kcal * 4.1868).toFixed(3)));
        })
        .on('blur', function (e) {
            var input = $(this),
                kcal = toNumber(input.val().trim());
            if (!!kcal) {
                input.val(localizedNumber(kcal));
            }
        });

    $('input[name="nuts[kj]"]')
        .on('keyup keydown keypress', function (e) {
            var kj = toNumber($(this).val().trim());
            $('input[name="nuts[calories]"]').val(localizedNumber((kj * 0.238845897).toFixed(3)));
        })
        .on('blur', function (e) {
            var input = $(this),
                value = toNumber(input.val().trim());
            if (!!value) {
                input.val(localizedNumber(value));
            }
        });

    $('.xproduct-ingredient-sync-group input:checkbox').on('click', function() {
        var cb = $(this),
            syncAddUrl = cb.attr('data-sync-add'),
            syncRemoveUrl = cb.attr('data-sync-remove'),
            syncRemoveProductUrl = cb.attr('data-sync-remove-product');

        var do_ajax = function (url, checked) {
            $.ajax({
                url: url,
                beforeSend: function () {
                    $('html').addClass('async-loading');
                },
                success: function (data) {
                    cb.prop('checked', checked);
                    $('.modal').modal('hide');
                }
            });
        };

        if (cb.prop('checked')) {
            modal_confirm(cb.attr('data-confirm-sync-add'), function () {
                do_ajax(syncAddUrl, true);
            });
        }
        else {
            modal_confirm(
                cb.attr('data-confirm-sync-remove'),
                function () {
                    do_ajax(syncRemoveProductUrl, false);
                },
                function () {
                    do_ajax(syncRemoveUrl, false);
                });
        }
        return false;
    });

    function pagination() {
        if (!$('.pagination-footer').hasClass('hidden')) {
            $('.pagination-page-index, .pagination-back, .pagination-next').removeClass('hidden');
            var current_page = 0, start = 0, end = 0;
            var page_count = $('.pagination-page-index').length;
            $('.pagination-page-index').each(function() {
                current_page++;
                if ($(this).hasClass('active-page')) { return false; }
            });
            if (current_page < page_count) {
                if (page_count - current_page <= 2) {
                    end = current_page + (page_count - current_page);
                }
                else {
                    end = current_page + 2;
                }
            } else {
                $('.pagination-next').addClass('hidden');
                end = page_count;
            }
            if (current_page > 1) {
                if (end - 3 >= 1) {
                    start = end - 3;
                }
            } else {
                $('.pagination-back').addClass('hidden');
                start = current_page;
                if (start + 3 <= page_count) {
                    end = start + 3;
                }
            }
            var i = 0;
            $('.pagination-page-index').each(function() {
                i++;
                if (i > end || i < start) {
                    $(this).addClass('hidden');
                }
            });
        }
    }

    pagination();

    $('input[name="user-ingredients-filter"]').on('updated', function() {
        var uif = $(this).val();
        var currUrl = $('.user-ing-filter').data('url');
        // change uif filter parameter in url
        var newUrl = currUrl.replace(/(uif=(own_ingredients|all_ingredients))/g, 'uif='+uif);
        // reset page to first
        newUrl = newUrl.replace(/(page=([\d]*))/, 'page=1');
        // reset search string to null
        newUrl = newUrl.replace(/(q=([^&]*))/, 'q=');
        // reset shop filter to all_shops, if user will choose own_ingredients only
        if(uif == "own_ingredients"){
            newUrl = newUrl.replace(/(sf=([^&]*))/, 'sf=all_shops');
        }

        document.location.href = newUrl;
    });

    $('input[name="ui-supplier-filter"]').on('updated', function() {
        var sf = $(this).val();
        var currUrl = $('.user-ing-filter').data('url');
        // change sf filter parameter in url
        var newUrl = currUrl.replace(/(sf=([^&]*))/, 'sf='+sf);
        // reset page to first
        newUrl = newUrl.replace(/(page=([\d]*))/, 'page=1');
        // reset search string to null
        newUrl = newUrl.replace(/(q=([^&]*))/, 'q=');
        document.location.href = newUrl;
    });

    $('.pagination-page-index, .pagination-back, .pagination-next').on('click', function() {
        if ($(this).hasClass('pagination-page-index')) {
            var current_page = $(this).html();
        } else if ($(this).hasClass('pagination-back')) {
            var current_page = Number($('.active-page').html()) - 1;
        } else {
            var current_page = Number($('.active-page').html()) + 1;
        }
        var currUrl = $(this).data('url');
        // change page filter parameter in url
        var newUrl = currUrl.replace(/(page=([\d]*))/, 'page='+current_page);
        document.location.href = newUrl;
    });

    $('.replace-all-checkbox-container').on('change', function() {
        if($(this).find('input').prop('checked')) {
            $(this).find('input').val("1");
            $('.suggested-recipe-positioning').find('input').prop('checked', true);
            $('.suggested-recipe-positioning').find('input').val("1");
            if ($('input[name="ingredient_that_will_replace"]').attr('data-ingredient-id') != '' && $('input[name="ingredient_that_will_replace"]').attr('data-ingredient-type') != '') {
                $('.ing-replace-save-btn').attr('disabled', false);
            }
        } else {
            $(this).find('input').val("");
            $('.suggested-recipe-positioning').find('input').prop('checked', false);
            $('.suggested-recipe-positioning').find('input').val("");
            $('.ing-replace-save-btn').attr('disabled', true);
        }
    });

    $('.suggested-recipe-positioning').on('change', function() {
        var checked_recipes_number = 0;
        $('.suggested-recipe-positioning').each(function () {
            if ($(this).find('input').prop('checked')) { checked_recipes_number++; }
        });
        if (!$(this).find('input').prop('checked')) {
            $('.replace-all-checkbox-container').find('input').prop('checked', false);
            $('.replace-all-checkbox-container').find('input').val("");
            $(this).find('input').val("");
            if (checked_recipes_number == 0) {
                $('.ing-replace-save-btn').attr('disabled', true);
            }
        } else {
            $(this).find('input').val("1");
            var recipes_number = $('.suggested-recipe-positioning').length;
            if (checked_recipes_number == recipes_number) {
                $('.replace-all-checkbox-container').find('input').prop('checked', true);
                $('.replace-all-checkbox-container').find('input').val("1");
            }
            if (checked_recipes_number > 0) {
                if ($('input[name="ingredient_that_will_replace"]').attr('data-ingredient-id') != '' && $('input[name="ingredient_that_will_replace"]').attr('data-ingredient-type') != '') {
                    $('.ing-replace-save-btn').attr('disabled', false);
                }
            }
        }
    });

    $('input[name="ingredient_that_will_replace"]').on('keydown', function(e) {
        var $this = $(this);

        if ($this.val() == 0) {
            $('.tt-hint').css('display', 'none');
        }
        $('.ing-replace-save-btn').attr('disabled', true);
        $(this).attr('data-ingredient-id', '');
        $(this).attr('data-ingredient-type', '');
        $(this).attr('data-product-group-id', '');
        $(this).attr('data-recipe-id', '');
    });

    $('.ing-replace-save-btn-div').on('click', function() {
        var affectedRecipeIds = [];
        var recipeId = $('.from-recipe').html();
        var ingredientIdThatWillBeReplaced = $('.ing-id-that-will-be-replaced').html();
        var ingredientTypeThatWillBeReplaced = $('.ing-type-that-will-be-replaced').html();
        var ingredientIdThatWillReplace = $('input[name="ingredient_that_will_replace"]').attr('data-ingredient-id');
        var productGroupIdThatWillReplace = $('input[name="ingredient_that_will_replace"]').attr('data-product-group-id');
        var recipeIdThatWillReplace = $('input[name="ingredient_that_will_replace"]').attr('data-recipe-id');
        var ingredientTypeThatWillReplace = $('input[name="ingredient_that_will_replace"]').attr('data-ingredient-type');
        $('input[name="replace_recipes"]').each(function() {
            if ($(this).prop('checked')) {
                affectedRecipeIds.push($(this).attr('data-recipe-id'));
            }
        });
        if (ingredientIdThatWillReplace && affectedRecipeIds.length > 0) {
            $.ajax({
                url: '/recipes/ingredients/' + ingredientIdThatWillBeReplaced + '/save_replace',
                type: 'post',
                beforeSend: function() {
                    htmlLoading();
                },
                data: {
                    ingredient_type_that_will_be_replaced: ingredientTypeThatWillBeReplaced,
                    ingredient_id_that_will_replace: ingredientIdThatWillReplace,
                    ingredient_type_that_will_replace: ingredientTypeThatWillReplace,
                    affected_recipe_ids: affectedRecipeIds,
                    product_group_id_that_will_replace: productGroupIdThatWillReplace,
                    recipe_id_that_will_replace: recipeIdThatWillReplace,
                    from_recipe: recipeId,
                    _confirmed: true
                },
                success: function() {
                    $('.ing-replace-save-btn').attr('disabled', true);
                    $('.ing-replace-save-btn').html($('.data-loading-text').html());
                },
                complete: function() {
                    htmlLoading(false);
                }
            });
        }
    });

    $('.ing-replace-back-btn').on('click', function() {
        window.history.back(-1);
        return false;
    });

    $('.open-locations-content').on('click', function() {
        $(this).find('.open-locations-arrow').toggleClass('opened');
        $(this).toggleClass('block-display-align-items');
        $(this).find('.closed-block-message').toggleClass('hidden');
        $(this).find('.all-locations-listed').toggleClass('hidden');
    });

    $('.recipe-content').on('mouseenter', function() {
        $(this).find('.open-recipe-in-new-window').removeClass('hidden');
    }).on('mouseleave', function() {
        $(this).find('.open-recipe-in-new-window').addClass('hidden');
    });

    $('.open-recipe-in-new-window').on('click', function() {
        window.open($(this).attr('data-url'));
    });

    if ($('input[name="ingredient_that_will_replace"]')) {
        // detailed ingredients search
        var ingredients_detailed_search_timeout;
        $('body').on('submit', '.ingredients-detailed-search', function () {
            var form = $(this);
            form.parents('.modal').first().find('.btn-submit').button('loading');

            clearTimeout(ingredients_detailed_search_timeout);
            form.save(function (data) {
                if (data.html) {
                    var results = form.find('.results .ingredients');
                    if (results.is(':visible')) {
                        results.html(data.html);
                    }
                }
            });
            return false;
        });
        // detailed search autosubmit
        var ingredients_detailed_search = function () {
            var form = $('.ingredients-detailed-search');
            var sform = form.serialize();
            if (form.data('sform') != sform) {
                clearTimeout(ingredients_detailed_search_timeout);
                ingredients_detailed_search_timeout = setTimeout(function () {
                    clearTimeout(ingredients_detailed_search_timeout);
                    form.data('sform', sform);
                    form.submit()
                }, 500);
            }
        };
        // just to be sure. reset autosubmit if modal closed
        $('body').on('hide.bs.modal', '.modal.detailed-search-modal', function () {
            clearTimeout(ingredients_detailed_search_timeout);
            ingredients_detailed_search = function () {
            };
        });
        $('body').on('change keydown keyup keypress', '.ingredients-detailed-search input[name=q]', function () {
            $('.ing-replace-save-btn').attr('disabled', true);
            ingredients_detailed_search();
        });
        // click on result add ingredient
        $('body').on('click', '.detailed-search-modal .results li[data-id], .detailed-search-modal .results li[data-recipe-id]', function () {
            var $this = $(this), id = $this.attr('data-id'),
                recipe_id = $this.attr('data-recipe-id'),
                modal = $('.detailed-search-modal');
            var form = $this.form();

            $.ajax({
                url: form.attr('data-url-selected-ing'),
                method: 'post',
                data: {
                    id: id
                },
                success: function (data) {
                    modal.modal('hide');
                    data.no_autocomplete = true;
                    $('.ing-replace-save-btn').attr('disabled', false);
                    $('input[name="ingredient_that_will_replace"]').val(data['name']);
                    $('input[name="ingredient_that_will_replace"]').attr('data-ingredient-type', data['type']);
                    $('input[name="ingredient_that_will_replace"]').attr('data-ingredient-id', data['ingredient_id']);
                    $('input[name="ingredient_that_will_replace"]').attr('data-product-group-id', data['product_group_id']);
                }
            });
            return false;
        });
    }
});

