import {htmlLoading} from "./global";
$( document ).ready(function() {
    $('form.service-connect').on('submit', function() {
        var form = $(this);

        form.save(function(data) {
            if (data) {
                if ('market_select' in data) {
                    form.find('select[name=market]').append($(data.market_select))
                        .closest('.hidden').hide().slideDown().removeClass('hidden');
                    form.append('<input type="hidden" name="market_select" value="1" />');
                }
                if (data.status) {
                    if (data.status == 'W') {
                        var infobox = form.closest('.infobox'),
                            configbox = infobox.prev();

                        configbox.find('.label-default').addClass('hidden');
                        configbox.find('.label-info').removeClass('hidden');
                        configbox.find('.btn').remove();
                        infobox.remove();
                    }
                }
            }
        });
        return false;
    });

    $('.service-disconnect').on('click', function() {
        var btn = $(this),
            url = btn.attr('data-service-disconnect');

        $.ajax({
            url: url,
            beforeSend: function() {
                $('html').addClass('async-loading');
            },
            success: function (data) {
                if (data.status) {
                    if (data.status == 'I') {
                        var infobox = btn.closest('.infobox'),
                            configbox = infobox.prev();

                        configbox.find('.label-success').addClass('hidden');
                        configbox.find('.label-default').removeClass('hidden');
                        configbox.find('.btn').remove();
                        infobox.remove();
                    }
                }
            }
        });
        return false;
    });

    $('#cash-registers .r2o-revoke').on('click', function() {
        var btn = $(this),
            url = btn.attr('href');

        $.ajax({
            url: url,
            beforeSend: function() {
                htmlLoading();
            },
            success: function (data) {
                if (data.status) {
                    if (data.status == 'success') {
                        var infobox = btn.closest('.infobox'),
                            configbox = infobox.prev();

                        configbox.find('.label-success').addClass('hidden');
                        configbox.find('.label-default').removeClass('hidden');
                        configbox.find('.btn').remove();
                        infobox.remove();
                    }
                }
            }
        });
        return false;
    });

    $('#databases input:checkbox').on('click', function() {
        var cb = $(this),
            url = cb.attr('data-toggle');

        $.ajax({
            url: url,
            beforeSend: function() {
                htmlLoading();
            },
            success: function (data) {
                if (data.status) {
                    cb.prop('checked', (data.status == 'A'));
                    var infobox = cb.closest('.infobox');
                    infobox.prev().find('.label-success').toggleClass('hidden', !infobox.find('[type=checkbox]:checked').length);
                }
            }
        });
        return false;
    });

    // if infobox is collapsing, toggle class in config-box
    $('.connections .collapse').on('show.bs.collapse', function() {
        $(this).prev('.config-box').addClass('open');
        $(this).siblings('.collapse.in').collapse('hide');
    }).on('hide.bs.collapse', function() {
        $(this).prev('.config-box').removeClass('open');
    });
    
    // open on click in config-box
    $('body').on('click', '.connections .config-box', function(e) {
        $(this).next('.collapse').collapse('toggle');
    });

    $('#cash-registers input:checkbox.default').on('click', function() {
        var cb = $(this),
            url = cb.attr('data-settings');

        $.ajax({
            url: url,
            beforeSend: function() {
                $('html').addClass('async-loading');
            },
            success: function (data) {
                var checked = data['checked'];
                if (typeof checked != 'undefined') cb.prop('checked', checked);
            }
        });
        return false;
    });


});
