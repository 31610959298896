import Handlebars from './../../handlebars-v1.3.0.min'

$(document).ready(function () {
    if (!$('#business-information-tabs').length) {
        return;
    }

    /**
     * Global
     */

    $('#restaurant_chooser').change(function () {
        var url = $(this).attr('data-url');
        $.ajax({
            type: "POST",
            url: url,
            data: {
                cost_center_id: $(this).val()
            },
            success: function (data) {
                notification(data.message);
            },
            error: function (jqXHR, textStatus, errorThrown) {

            }
        });
        return false;
    });

    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        initColorSelector();
        initGoogleMaps();
        initImageUpload();

        initGoogleMapsCostCenters();
    });

    $('a[data-toggle="tab"]').on('hidden.bs.tab', function (e) {
        return;
         // previous active tab
        var url = $(e.relatedTarget).attr('data-url');
        $.ajax({
            url: url,
            success: function (data) {
                //notification(data.message);
                $('#locations').html(data.markup);
            },
            error: function (jqXHR, textStatus, errorThrown) {

            }
        });
        return false;
    })

    /**
     * Business information
     */
    $('body').on('click', '.locations_crud', function (e) {
        e.preventDefault();
        var url = $(this).attr('href');

        $.ajax({
            url: url,
            success: function (data) {
                if(data.message) {
                    notification(data.message);
                }
                $('#locations').html(data.markup);
                $('.nav-tabs a[href="#locations"]').tab('show');
                initColorSelector();
                initGoogleMaps();
                initImageUpload();
            },
            error: function (jqXHR, textStatus, errorThrown) {

            }
        });
        return false;
    });

    $('body').on('click', '.locations_cancel', function (e) {
        e.preventDefault();
        var url = $(this).attr('data-url');
        $.ajax({
            url: url,
            success: function (data) {
                //notification(data.message);
                $('#locations').html(data.markup);
            },
            error: function (jqXHR, textStatus, errorThrown) {

            }
        });
        return false;
    });

    $('a[data-toggle="tab"]').on('click', function (e) {
        var url = $(this).attr('data-url');
        var hl = $(this).attr('href');

        $.ajax({
            url: url,
            success: function (data) {
                $(hl).html(data.markup);
            },
            error: function (jqXHR, textStatus, errorThrown) {

            }
        });
    });

    $('#locations').on('submit', 'form', function (e) {
        e.stopPropagation();
        e.preventDefault();
        e.stopImmediatePropagation();

        var formData = $(this).serialize();

        $.ajax({
            type: "POST",
            url: $(this).attr('action'),
            data: formData,
            success: function (data) {
                notification(data.message);
                $('#locations').html(data.markup);
            },
            error: function (jqXHR, textStatus, errorThrown) {

            }
        });

        return false;
    });


    $('body').on('click', '.cc_crud', function (e) {
        e.preventDefault();
        var url = $(this).attr('href');

        $.ajax({
            url: url,
            success: function (data) {
                if(data.message) {
                    notification(data.message);
                }
                $('#cost-centers').html(data.markup);
                $('.nav-tabs a[href="#cost-centers"]').tab('show');
                initGoogleMapsCostCenters();
            },
            error: function (jqXHR, textStatus, errorThrown) {

            }
        });
        return false;
    });

    $('body').on('click', '.cc_cancel', function (e) {
        e.preventDefault();
        var url = $(this).attr('data-url');
        $.ajax({
            url: url,
            success: function (data) {
                //notification(data.message);
                $('#cost-centers').html(data.markup);
            },
            error: function (jqXHR, textStatus, errorThrown) {

            }
        });
        return false;
    });

    $('body').on('click', '.company_crud', function (e) {
        e.preventDefault();
        var url = $(this).attr('href');

        $.ajax({
            url: url,
            success: function (data) {
                if(data.message) {
                    notification(data.message);
                }
                $('#company').html(data.markup);
                $('.nav-tabs a[href="#company"]').tab('show');
                initImageUpload();
            },
            error: function (jqXHR, textStatus, errorThrown) {

            }
        });
        return false;
    });

    $('body').on('click', '.company_cancel', function (e) {
        e.preventDefault();
        var url = $(this).attr('data-url');
        $.ajax({
            url: url,
            success: function (data) {
                //notification(data.message);
                $('#company').html(data.markup);
            },
            error: function (jqXHR, textStatus, errorThrown) {

            }
        });
        return false;
    });

    $('#cost-centers').on('submit', 'form', function (e) {
        e.stopPropagation();
        e.preventDefault();
        e.stopImmediatePropagation();

        var form = $(this), formData = form.serialize();

        $.ajax({
            type: "POST",
            url: $(this).attr('action'),
            data: formData,
            success: function (data) {
                notification(data.message);
                $('#cost-centers').html(data.markup);
            },
            error: function (jqXHR, textStatus, errorThrown) {

            }
        });

        return false;
    });

    $('#company').on('submit', 'form', function (e) {
        var form = $(this);

        form.save(function(data) {
            $('.company_cancel').click()
        });

        return false;
    });

    function initColorSelector()
    {
        var cs = $('.colorselector');
        if (cs.length) {
            cs.colorselector();
        }
    }

    function initGoogleMaps()
    {
        var gmap = $('#gmap:visible');

        if (gmap.length) {
            google_map_init = function() {
                var map_canvas = gmap.get(0);
                var position = {
                    coords: {
                        latitude: 48.2087079999999,
                        longitude: 16.372302999999988
                    }
                };

                var country = $('html').attr('country');
                if (country == 'DE') {
                    position = {
                        coords: {
                            latitude: 52.687108,
                            longitude: 13.237246
                        }
                    }
                } else
                if (country == 'CH') {
                    position = {
                        coords: {
                            latitude: 46.948337, 
                            longitude: 7.443965
                        }
                    }
                }   

                var latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                var map_options = {
                    center: latlng,
                    zoom: 13,
                    disableDefaultUI: true,
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                }
                var map = new google.maps.Map(map_canvas, map_options);
                var marker = new google.maps.Marker({
                    //position: latlng,
                    map: map,
                    title: $('#restaurant').val()
                });

                var keydown_timeout;
                var address = $('#address');
                address.on('change select update', function(e) {
                    var that = $(this), val = that.val();
                    var geocoder = new google.maps.Geocoder();

                    if (val == 0 || $.trim(val).length == 0) {
                        return
                    }

                    geocoder.geocode({'address': val}, function(results, status) {
                        if (status == google.maps.GeocoderStatus.OK) {
                            map.setCenter(results[0].geometry.location);
                            map.fitBounds(results[0].geometry.viewport);
                            if (that.hasClass('google-map-country')) {
                                region.val('');
                                ac_region.setComponentRestrictions({ country: val });
                            }
                            marker.setPosition(results[0].geometry.location);
                        }
                    });
                }).on('keydown', function() {
                    clearTimeout(keydown_timeout);
                    keydown_timeout = setTimeout(function() {
                        address.triggerHandler('update');
                    }, 400);
                }).triggerHandler('update');
            };

            $.getScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyDOy0Ns1iCrXpJeZ4phREHsjMI21hzpVlA&sensor=TRUE&callback=google_map_init&libraries=places&language='+($('html').attr('lang') || 'en'));
        }
    }

    function initImageUpload()
    {
        $('body').on('click', '.fileupload-img', function(e) {
            var input = $(this),
                url = input.attr('data-url'),
                photos_list = $(input.attr('data-photos-container')),
                limit = input.attr('data-limit'),
                limit_remove_first = input.attr('data-limit-remove-first'),
                prepend_photo = input.attr('data-prepend-photo') == 'true',
                connect_with = input.attr('data-connect-with');

            if (!input.hasClass('fileupload-added')) {
                input.fileupload({
                    url: input.attr('data-url'),
                    dataType: 'json',
                    sequentialUploads: false,
                    limitConcurrentUploads: 3,
                    done: function (e, data) {
                        if (data.result.imgs) {
                            $.each(data.result.imgs, function (index, imgdata) {
                                if (data.context) {
                                    var img = data.context.img.find('img')
                                        .attr('src', imgdata.thumb)
                                        .attr('data-id', imgdata.id)
                                        .attr('data-url', imgdata.url);

                                    img.siblings('[type=hidden].id').val(imgdata.id);

                                    // remove url
                                    if ('remove_url' in imgdata) {
                                        data.context.img.find('.btn-remove').attr('data-href', imgdata.remove_url)
                                    }

                                    data.context.img.removeClass('uploading');

                                    // inform connect_with
                                    if (connect_with) {
                                        $(connect_with).trigger('image-added', [ data.context.img ]);
                                    }

                                    if (limit) {
                                        var photos = photos_list.find('img');
                                        if (photos.length > limit && limit_remove_first) {
                                            if (prepend_photo) {
                                                photos = $(photos.get().reverse());
                                            }
                                            photos.each(function(i) {
                                                if (i < limit) {
                                                    $(this).parents('.photo').first().remove();
                                                }
                                            });
                                        }
                                    }
                                }
                            });
                        }
                    },
                    submit: function() {
                        return true
                    },
                    progress: function(e, data) {
                        if (data.context) {
                            var progress = parseInt(data.loaded/data.total * 100, 10) + '%';
                            data.context.bar.css('width', progress).text(progress);
                        }
                    },
                    add: function(e, data) {
                        // check limit to disable upload
                        if (limit && !limit_remove_first) {
                            var photos = photos_list.find('.photo');
                            if (photos.length >= limit) {
                                photos.trigger('limit-reached');
                                return false;
                            }
                        }

                        var temp_name = '';
                        if (data.files && data.files.length > 0 && data.files[0].name) {
                            temp_name = data.files[0].name;
                        }

                        var newimg = $(Handlebars.compile(photos_list.find('script.tmpl').html())({ uploading: 'uploading', temp_name: temp_name }));
                        var newimg_progress_bar = newimg.find('.progress-bar');
                        if (prepend_photo) {
                            photos_list.prepend(newimg);
                        } else {
                            photos_list.append(newimg);
                        }

                        // check again to show msg rightaway
                        if (limit && !limit_remove_first) {
                            photos = photos_list.find('.photo');
                            if (photos.length >= limit) {
                                photos.trigger('limit-reached');
                            }
                        }

                        data.context = {
                            img: newimg.first(),
                            bar: newimg_progress_bar.first()
                        };

                        photos_list.removeClass('hidden');
                        photos_list.slideDown();
                        data.submit();
                    },
                    fail: function(e, data) {
                        if (data.context) {
                            data.context.img.remove();
                            //notification(translations.unknown_upload_error);
                            //notification('upload error')
                            var photos = photos_list.find('.photo');
                            if (!photos.length) {
                                photos.addClass('hidden');
                            } else {
                                photos.removeClass('hidden');
                            }
                        }
                    },
                    always: function(e, data) {
                        // check again to fix it
                        setTimeout(function() {
                            var photos = photos_list.find('.photo');
                            if (limit && !limit_remove_first) {
                                if (photos.length >= limit) {
                                    photos.trigger('limit-reached');
                                } else {
                                    photos.trigger('limit-unreached');
                                }
                            }
                        }, 100);
                    }
                }).prop('disabled', !$.support.fileInput)
                    .addClass('fileupload-added')
                    .parent().addClass($.support.fileInput ? undefined : 'disabled')
            }
        });
    }

    function initGoogleMapsCostCenters()
    {
        var gmap = $('#cc_gmap:visible');

        if (gmap.length) {
            google_map_init = function() {
                var map_canvas = gmap.get(0);
                var position = {
                    coords: {
                        latitude: 48.2087079999999,
                        longitude: 16.372302999999988
                    }
                };

                var country = $('html').attr('country');
                if (country == 'DE') {
                    position = {
                        coords: {
                            latitude: 52.687108,
                            longitude: 13.237246
                        }
                    }
                } else
                if (country == 'CH') {
                    position = {
                        coords: {
                            latitude: 46.948337,
                            longitude: 7.443965
                        }
                    }
                }

                var latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                var map_options = {
                    center: latlng,
                    zoom: 13,
                    disableDefaultUI: true,
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                }
                var map = new google.maps.Map(map_canvas, map_options);
                var marker = new google.maps.Marker({
                    //position: latlng,
                    map: map,
                    title: $('#cc_name').val()
                });

                var keydown_timeout;
                var address = $('#cc_address');
                address.on('change select update', function(e) {
                    var that = $(this), val = that.val();
                    var geocoder = new google.maps.Geocoder();

                    if (val == 0 || $.trim(val).length == 0) {
                        return
                    }

                    geocoder.geocode({'address': val}, function(results, status) {
                        if (status == google.maps.GeocoderStatus.OK) {
                            map.setCenter(results[0].geometry.location);
                            map.fitBounds(results[0].geometry.viewport);
                            if (that.hasClass('google-map-country')) {
                                region.val('');
                                ac_region.setComponentRestrictions({ country: val });
                            }
                            marker.setPosition(results[0].geometry.location);
                        }
                    });
                }).on('keydown', function() {
                    clearTimeout(keydown_timeout);
                    keydown_timeout = setTimeout(function() {
                        address.triggerHandler('update');
                    }, 400);
                }).triggerHandler('update');
            };

            $.getScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyDOy0Ns1iCrXpJeZ4phREHsjMI21hzpVlA&sensor=TRUE&callback=google_map_init&libraries=places&language='+($('html').attr('lang') || 'en'));
        }
    }
});
