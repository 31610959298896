import {htmlLoading} from "./global";

$(document).ready(function () {
    var order_view = $('.recipe-order-page');
    if (!order_view.length) {
        return;
    }

    // order from recipe
    var update_cart_product = function(product, data) {

        product.toggleClass('in-cart', (data.product_number > 0));
        product.removeClass('updated');

        var realPackaging = null;
        var packagingGrams = data.packaging_quantity * 1000;

        if (packagingGrams < 1000)
        {
            product.find('.item-quantity .quantity-read-only .item-quantity-sum').text(`${data.product_number} x ${data.packaging_quantity} ${data.packaging}`);
        }
        else
        {
            product.find('.item-quantity .quantity-read-only .item-quantity-sum').text(data.product_number + ' ' + data.packaging);
        }

        product.find('.quantity-cart-total .sum').text(data.product_total);
        product.find('.item-quantity').removeClass('edit different-quantity');
        product.attr('data-cart-quantity', data.product_number);
        //product.find('.item-quantity .quantity-in-cart .quantity').text(data.product_number + ' ' + data.packaging);
        // product.find('.item-quantity .quantity-read-only .item-quantity-sum').text(data.product_number + ' ' + data.packaging);

        var quantity_input = product.find('input.quantity');
        var quantity_sum = product.find('.item-quantity-sum');

        if (data.product_number > 0) {
            if (product.attr('data-quantity') != data.product_number) {
                product.find('.item-quantity').addClass('different-quantity');
            }
            quantity_input.val(data.product_number);
        } else {
            quantity_input.val(product.attr('data-quantity'));
        }
        quantity_input.data('current-value', quantity_input.val());

        var shop = product.closest('.shop');
        shop.toggleClass('cart-empty', (data.products_number == 0));
        shop.find('.cart-product-number').text(data.products_number_txt);
    };

    var update_quantity = function(product) {
        clearTimeout(product.data('updateTimeout'));
        product.data('updateTimeout', setTimeout(function() {
            var input_quantity = product.find('.item-quantity input.quantity'),
                val = input_quantity.val();

            if (val == input_quantity.data('current-value')) {
                product.find('.item-quantity').removeClass('edit');
                return;
            }

            $.ajax({
                url: product.find('.plus-minus').attr('data-url'), 
                type: 'post',
                data: {
                    quantity: val,
                    packaging: product.attr('data-packaging')
                },
                beforeSend: function() {
                    htmlLoading();
                    product.find('.item-add-to-cart .btn').addClass('loading disabled');
                },
                success: function(data) {
                    update_cart_product(product, data); 
                },
                complete: function() {
                    product.find('.item-add-to-cart .btn').removeClass('loading disabled');
                }
            });
        }, 400));

        return false; 
    };

    // add product to cart
    $('body').on('click', '.add-product-from-recipe', function() {
        var $this = $(this), product = $this.closest('.product');

        product.find('.item-add-to-cart .btn').addClass('loading disabled');
        $this.async(function(data) {
            product.find('.item-add-to-cart .btn').removeClass('loading disabled');
            update_cart_product($this.closest('.product'), data);
        });
        return false;
    });

    // edit item quantity
    $('body').on('click', '.product .item-quantity .quantity-read-only, .product .update-product-from-recipe, .product .overwrite-product-from-recipe', function() {
        var $this = $(this), product = $this.closest('.product');
        product
            .siblings()
            .find('.item-quantity.edit')
            .removeClass('edit');

        var input_quantity = product
            .find('.item-quantity')
            .addClass('edit')
            .find('input.quantity')
            .select()

        var curr_value = input_quantity.data('current-value');

        if (typeof curr_value == 'undefined' || !curr_value.length) {
            input_quantity.data('current-value', input_quantity.val()); 
        }
    });

    // plus/minus
    $('body').on('click', '.product .item-quantity .plus-minus[data-url] a', function() {
        var $this = $(this), 
            product = $this.closest('.product'),
            wrapper = $this.closest('.plus-minus'),
            input_quantity = wrapper.find('input.quantity'),
            quantity = input_quantity.val();
            
        if ($this.hasClass('plus-icon')) {
            quantity++;
        } else {
            quantity--; 
        }
        if (quantity < 0) {
            quantity = 0;   
        }

        input_quantity.val(quantity);

        update_quantity(product);
    });

    $('body')
        .on('blur', '.product .item-quantity .plus-minus input.quantity', function() {
            update_quantity($(this).closest('.product'));
        })
        .on('keydown', '.product .item-quantity .plus-minus input.quantity', function(e) {
            if (e.which == 13) {    // enter
                $(this).blur();
                return false;
            }
        });

    // portions change
    $('body').on('keydown keyup keypress', 'persons input', function() {
        var $this = $(this), 
            url = $this.attr('data-url'), 
            val = $this.val();

        clearTimeout($this.data('updatePersons'));

        if (val > 0) {
            $this.data('updatePersons', setTimeout(function() {
                $.ajax({
                    url: url,
                    data: {
                        portions: $this.val() 
                    },
                    beforeSend: function() {
                        htmlLoading();
                    },
                    success: function(data) {
                        $('.recipe-order').replaceWith(data);

                        $('.recipe-order .product.in-cart').each(function() {
                            var $this = $(this);

                            if ($this.attr('data-quantity') != $this.attr('data-cart-quantity')) {
                                $this.find('.quantity-in-cart .quantity').blinkMe();
                            }
                        })
                    }
                })
            }, 400));
        }
    });

    ////////////////////////
    // end of document ready
});

