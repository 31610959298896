// functions for recipe form and recipe view
$( document ).ready(function() {
    $('.ing-err-inform').click(function() {
        var $this = $(this), id = $this.closest('.row.ing[data-id], .row.ing-row[data-id]').attr('data-id');

        if (id) {
            $.ajax({
                url: $this.attr('href'),
                data: { id: id }
            });
        }
        
        return false;
    });

    $('.lang-tabs li').on('click', function () {
        var lang = $(this).attr('data-lang');
        $('.recipeBlock .tab-pane').removeClass('in active');
        $('.recipeBlock .tab-pane[data-lang="' + lang + '"]').addClass('in active');
    });
});
