import Handlebars from './../handlebars-v1.3.0.min'

$( document ).ready(function() {

(function() {
    $('body').on('shown.bs.modal', '.modal.files-manage-modal', function() {
        var modal = $(this);

        // sortable
        modal.find('.files.sortable').sortable({
            items: '.file',
            axis: 'y',
            containment: 'parent',
            cursor: 'move',
            handle: '.btn-move, > div',
            update: function(e, ui) {
                var $this = $(this);

                $.ajax({
                    url: $this.attr('data-resort-url'),
                    type: 'post',
                    data: $this.find('input.id').serialize(),
                    success: function() {
                        // resort carousel
                        var carousel = $('.carousel-inner');

                        $($this.find('input.id').get().reverse()).each(function() {
                            var id = $(this).val();
                            var item = carousel.find('img[data-id='+id+']').closest('.item');
                            carousel.prepend(item);
                        });

                        carousel.closest('.carousel').trigger('re-init');
                    }
                });
            }
        })

        // "limit-reached"
        .on('limit-reached', function() {
            $(this).siblings('.limit-msg').slideDown();
            modal.find('.fileinput-button').addClass('disabled');
        })
        // "limit-unreached"
        .on('limit-unreached', function() {
            $(this).siblings('.limit-msg').slideUp();
            modal.find('.fileinput-button').removeClass('disabled');
        })

        // delete
        modal.on('click', '.btn-remove[data-href]', function() {
            var btn = $(this);

            btn.async(function() {
                btn.closest('.file').slideUp(function() {
                    var file = btn.closest('.file');
                    var files = file.closest('.files');

                    $(this).remove();
                    $('.add-file').trigger('file-removed', [ file ]);

                    files.trigger('limit-unreached');

                    if (!files.find('.file').length) {
                        files.addClass('hidden');
                    }
                });
            });
        });

        // delete file question modal
        // on "shown", set z-index of gallery modal to 10
        // on "hidden" restore z-index
        $('body').on('shown.bs.modal', '.modal.question-modal', function() {
            modal.addClass('submodal');
        }).on('hidden.bs.modal', '.modal.question-modal', function() {
            modal.removeClass('submodal');
        });

        // edit values 
        modal.on('click', '[data-editable=true][data-target]', function() {
            var $this = $(this), file = $this.closest('.file'), target = $(file.find($this.attr('data-target')));
            if (target.length) {
                file.addClass('edit').siblings('.edit').removeClass('edit');
                target.focus().select();
            }
        });
        // close "edit description"
        modal.on('click', '.file .edit .close', function() {
            $(this).closest('.file').removeClass('edit');
        });
        // save description
        modal.on('submit', '.file .edit', function() {
            var $this = $(this), form = $this.closest('form');

            form.save(function(data) {
                var file = $this.closest('.file');

                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        var val = data[key];

                        // set value
                        var node = file.find('.'+key+':not(input):not(textarea)').text(val);

                        // if value empty, remove classe empty
                        if (val) {
                            node.removeClass('empty');

                        // or add "empty" and set placeholder from input as value
                        } else {
                            node.addClass('empty');

                            var edit_node = file.find('input.'+key+'textarea.'+key);
                            if (edit_node.length) {
                               node.val(edit_node.attr('placeholder'));
                            }
                        }
                    }
                }

                file.removeClass('edit');
            });
            return false;
        });
    });


    // modal on close
    $('body').on('hide.bs.modal', '.modal.file-manage-modal', function() {
        // disable event on delete modal
        $('body').off('shown.bs.modal', '.modal.question-modal')
            .off('hidden.bs.modal', '.modal.question-modal');
    });

    // image upload
    $('body').on('click', '.fileupload-btn', function(e) {
        var input = $(this),
            url = input.attr('data-url'),
            files_list = $(input.attr('data-files-container')),
            limit = input.attr('data-limit'),
            limit_remove_first = input.attr('data-limit-remove-first'),
            prepend_file = input.attr('data-prepend-file') == 'true',
            connect_with = input.attr('data-connect-with');

        if (!input.hasClass('fileupload-added')) {
            input.fileupload({
                url: input.attr('data-url'), 
                dataType: 'json',
                sequentialUploads: false,
                limitConcurrentUploads: 3,
                done: function (e, data) {
                    var files = [];

                    if (data.result.imgs) {
                        files = data.result.imgs;
                    } else
                    if (data.result.files) {
                        files = data.result.files;
                    }

                    if (files) {
                        $.each(files, function (index, fdata) {
                            if (data.context) {
                                var file = data.context.file;

                                if ('img' in data) {
                                    var img = file.find('img')
                                        .attr('src', fdata.thumb)
                                        .attr('data-id', fdata.id)
                                        .attr('data-url', fdata.url);
                                }
                                file.find('[type=hidden].id').val(fdata.id);

                                // remove url
                                if ('remove_url' in fdata) {
                                    file.find('.btn-remove').attr('data-href', fdata.remove_url)
                                }
                                // update url
                                if ('update_url' in fdata) {
                                    file.find('form.update').attr('action', fdata.update_url)
                                }
                                // download url
                                if ('download_url' in fdata) {
                                    file.find('.btn-download').attr('data-href', fdata.download_url)
                                }

                                file.removeClass('uploading');

                                for (var key in fdata) {
                                    if (fdata.hasOwnProperty(key)) {
                                        var val = fdata[key];

                                        // set value
                                        var node = file.find('.'+key+':not(input):not(textarea)').text(val);

                                        // if value empty, remove classe empty
                                        if (val) {
                                            node.removeClass('empty');

                                        // or add "empty" and set placeholder from input as value
                                        } else {
                                            node.addClass('empty');

                                            var edit_node = file.find('input.'+key+'textarea.'+key);
                                            if (edit_node.length) {
                                               node.val(edit_node.attr('placeholder'));
                                            }
                                        }
                                    }
                                }

                                // inform connect_with
                                if (connect_with) {
                                    $(connect_with).trigger('file-added', [ file ]);
                                }

                                if (limit) {
                                    var files = files_list.find('.file');
                                    if (files.length > limit && limit_remove_first) {
                                        if (prepend_file) {
                                            files = $(files.get().reverse());
                                        }
                                        files.each(function(i) {
                                            if (i < limit) {
                                                //$(this).parents('.file').first().remove();
                                                $(this).first().remove();
                                            }
                                        }); 
                                    }
                                }
                            }
                        });
                    } else {
                        console.log("no files");
                    }
                    if (data.result['product_id']) {
                        var editGallery = $('.product-file.add-file a.async'),
                            href = editGallery.attr('href');
                        if (!href.match(/\d+$/)) {
                            href += '/' + data.result['product_id'];
                            editGallery.attr('href', href);
                        }

                        $('input[name="product-id"]').val(data.result['product_id']);
                    }
                },
                submit: function() {
                    return true
                },
                progress: function(e, data) {
                    if (data.context) {
                        var progress = parseInt(data.loaded/data.total * 100, 10) + '%';
                        data.context.bar.css('width', progress).text(progress);
                    }
                },
                add: function(e, data) {
                    // check limit to disable upload
                    if (limit && !limit_remove_first) {
                        var files = files_list.find('.file');
                        if (files.length >= limit) {
                            files.trigger('limit-reached');
                            return false;
                        }
                    }

                    var temp_name = '';
                    if (data.files && data.files.length > 0 && data.files[0].name) {
                        temp_name = data.files[0].name;
                    }
                   
                    var file = $(Handlebars.compile(files_list.find('script.tmpl').html())({ uploading: 'uploading', temp_name: temp_name }));
                    var file_progress_bar = file.find('.progress-bar');
                    if (prepend_file) {
                        files_list.prepend(file);
                    } else {
                        files_list.append(file);
                    }

                    // check again to show msg rightaway
                    if (limit && !limit_remove_first) {
                        files = files_list.find('.file');
                        if (files.length >= limit) {
                            files.trigger('limit-reached');
                        }
                    }

                    data.context = {
                        file: file.first(),
                        bar: file_progress_bar.first()
                    };

                    files_list.removeClass('hidden');
                    files_list.slideDown();
                    data.submit();
                },
                fail: function(e, data) {
                    if (data.context) {
                        data.context.file.remove();
                        //notification(translations.unknown_upload_error);
                        //notification('upload error')
                        var files = files_list.find('.file');
                        if (!files.length) {
                            files.addClass('hidden');
                        } else {
                            files.removeClass('hidden');
                        }
                    }
                },
                always: function(e, data) {
                    // check again to fix it
                    setTimeout(function() {
                        var files = files_list.find('.file');
                        if (limit && !limit_remove_first) {
                            if (files.length >= limit) {
                                files.trigger('limit-reached');
                            } else {
                                files.trigger('limit-unreached');
                            }
                        }
                    }, 100);
                }
            }).prop('disabled', !$.support.fileInput)
            .addClass('fileupload-added')
            .parent().addClass($.support.fileInput ? undefined : 'disabled')
        }
    });
})();
})
