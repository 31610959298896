/* global functions */
var toggleRightSidebar;
var openRightSidebar;
var closeRightSidebar;
var openLeftSidebar;
var closeLeftSidebar;

$(document).ready(function () {
    var onSmartphone = $('.smartphone-menu').is(':visible');

    var isRightSidebarOpen = function() {
        return $('#right-menu').hasClass('expand');
    };
    var isLeftSidebarOpen = function() {
        return $('#left-menu').hasClass('expand');
    };
    function toggleLeftSidebar() {
        var open = isLeftSidebarOpen();
        var left_menu = $('#left-menu').toggleClass('expand');

        if (left_menu.hasClass('expand')) {
            history.pushState(null, null, window.location.pathname);
        }
        if (open != isLeftSidebarOpen() && onSmartphone) {
            left_menu.trigger(open ? 'sidebar.closed' : 'sidebar.open')
        }
    }
    toggleRightSidebar = function() {
        var open = isLeftSidebarOpen();
        var right_menu = $('#right-menu').toggleClass('expand');

        if (right_menu.hasClass('expand')) {
            history.pushState(null, null, window.location.pathname);
        }
        if (open != isLeftSidebarOpen() && onSmartphone) {
            left_menu.trigger(open ? 'sidebar.closed' : 'sidebar.open')
        }
    }
    openRightSidebar = function() {
        var right_menu = $('#right-menu');

        if (!right_menu.hasClass('expand')) {
            right_menu.addClass('expand');
            if (onSmartphone) { 
                right_menu.trigger('sidebar.open');
            }
            history.pushState(null, null, window.location.pathname);
        }
    }
    openLeftSidebar = function() {
        var left_menu = $('#left-menu');

        if (!left_menu.hasClass('expand')) {
            left_menu.addClass('expand');
            $('#top-navbar .nav-icon.burger').addClass('open');

            if (onSmartphone) { 
                left_menu.trigger('sidebar.open');
            }
            history.pushState(null, null, window.location.pathname);
        }
    }
    closeLeftSidebar = function() {
        closeBurger();
        var left_menu = $('#left-menu').removeClass('expand');
        if (onSmartphone) { 
            $('#top-navbar .nav-icon.burger').removeClass('open');
            left_menu.trigger('sidebar.closed');
        }
    }
    closeRightSidebar = function() {
        var right_menu = $('#right-menu').removeClass('expand');
        if (onSmartphone) { 
            right_menu.trigger('sidebar.closed');
        }
    }

    function closeBurger() {
        $('#top-navbar .nav-icon.burger').removeClass('open');
    }

    $('.offcanvas-overlay').click(function () {
        if ($('#right-menu').hasClass('expand')) {
            history.back(); 
            closeRightSidebar();
        }
        if ($('#left-menu').hasClass('expand')) {
            history.back(); 
            closeLeftSidebar();
        }
    });

    $('#top-navbar .nav-icon.burger').click(function () {
        toggleLeftSidebar();
        $(this).toggleClass('open');
    });
    $('#right-sidebar-toggle').click(function () {
        toggleRightSidebar();
    });

    // back key pressed and menus open 
    $(window).on("popstate", function (e) {
        if ($('#right-menu').hasClass('expand')) {
            closeRightSidebar();
            e.stopPropagation();
            e.preventDefault();
            e.stopImmediatePropagation();
            return false;
        }
        if ($('#left-menu').hasClass('expand')) {
            closeLeftSidebar();    
            e.stopPropagation();
            e.preventDefault();
            e.stopImmediatePropagation();
            return false;
        }
    });

});
